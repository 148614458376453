import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Amplify } from 'aws-amplify';
import {
    AuthSession,
    AuthUser,
    fetchAuthSession,
    getCurrentUser,
    signIn,
    SignInInput,
    SignInOutput,
    signOut,
} from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';

import { CognitoUserAttributes } from '../interfaces/Cognito.interface';

/**
 * Provides methods for working with AWS Cognito.
 *
 * The service is static and needs to be initialised in the apps entry
 * file by calling configure before it can be consumed by other services.
 *
 * @export
 * @class CognitoService
 */
export class CognitoService {
  /**
   * Configures AWS Amplify with the environment variables.
   *
   * @static
   * @memberof CognitoService
   */
  public static configure(): void {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
          userPoolClientId: `${process.env.REACT_APP_COGNITO_USER_WEB_CLIENT_ID}`,
        },
      },
    });

    cognitoUserPoolsTokenProvider.setKeyValueStorage(
      new CookieStorage({
        domain:
          process.env.REACT_APP_ENVIRONMENT?.toLowerCase() !== "development"
            ? window.location.hostname
            : "localhost",
        path: "/",
        expires: 30,
        sameSite: "strict",
        secure: true,
      })
    );
  }

  /**
   * Returns the current cognito session for the authenticated user.
   *
   * @static
   * @return { Promise<CognitoUserSession> }
   * @memberof CognitoService
   */
  public static async currentSession(): Promise<AuthSession> {
    return await fetchAuthSession();
  }

  /**
   * Returns the currently authenticated users Cognito attributes.
   *
   * @static
   * @return { (Promise<CognitoUserAttributes | null>) }
   * @memberof CognitoService
   */
  public static async userAttributes(): Promise<AuthUser | null> {
    try {
      return await getCurrentUser();
    } catch (error) {
      return null;
    }
  }

  /**
   * Signs in the user with cognito.
   *
   * @static
   * @param { CognitoAuthenticationRequest } credentials
   * @return { Promise<CognitoAuthenticationResult | null> }
   * @memberof CognitoService
   */
  public static async signIn(
    credentials: SignInInput
  ): Promise<SignInOutput | null> {
    try {
      await this.signOut();

      return await signIn(credentials);
    } catch (error) {
      return null;
    }
  }

  /**
   * Signs out the currently authenticated user.
   *
   * @static
   * @return { Promise<boolean> }
   * @memberof CognitoService
   */
  public static async signOut(): Promise<boolean> {
    try {
      await signOut();
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Parses a users Cognito attributes.
   *
   * @private
   * @static
   * @param { CognitoUserAttribute[] } attributes
   * @return { (CognitoUserAttributes | null) }
   * @memberof CognitoService
   */
  private static toCognitoUserAttributes(
    attributes: CognitoUserAttribute[]
  ): CognitoUserAttributes | null {
    try {
      let email: string | null = null,
        emailVerified: boolean | null = null,
        lastAuthenticated: number | null = null,
        locale: string | null = null,
        sub: string | null = null;

      for (let index = 0; index < attributes.length; index++) {
        const { Name, Value } = attributes[index];

        if (Name === "email") email = Value;
        if (Name === "email_verified") emailVerified = Value === "true";
        if (Name === "custom:last_authenticated")
          lastAuthenticated = Number(Value);
        if (Name === "locale") locale = Value;
        if (Name === "sub") sub = Value;
      }

      if (
        email === null ||
        emailVerified === null ||
        lastAuthenticated === null ||
        locale === null ||
        sub === null
      ) {
        return null;
      }

      return {
        email,
        emailVerified,
        lastAuthenticated,
        locale,
        sub,
      };
    } catch (error) {
      return null;
    }
  }
}
