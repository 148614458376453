import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import LoginPage from "./auth/Login";
import Documentation from "./dashboard/components/Documentation";
import Guide from "./dashboard/components/Guide";
import Home from "./dashboard/components/Home";
import Settings from "./dashboard/components/Settings";
import Dashboard from "./dashboard/Dashboard";
import ErrorPage from "./Error";
import Loading from "./loading/Loading";
import AuthService from "./services/Auth.service";
import { CognitoService } from "./services/Cognito.service";
import { IRootAppState } from "./store";


/**
 * Defines the shape of the Router state.
 *
 * @interface IRouterAppState
 */
interface IRouterAppState {
  /**
   * If the AppRouter itself has loaded.
   *
   * Considered true after the component mounts and has triggered the auth checks.
   *
   * @type { boolean }
   * @memberof IRouterAppState
   */
  readonly hasRouterLoaded: boolean;
}

/**
 * Defines the props that will be provided to the AppRouter.
 *
 * @interface IRouterAppProps
 */
interface IRouterAppProps {
  readonly isAuthenticated: boolean;
  readonly isAppLoading: boolean;
}

/**
 * Defines the routes that require authentication to access.
 *
 * @return { JSX.Element }
 */
function AuthenticatedRouter(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}>
        <Route path="/" element={<Home />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/settings" element={<Settings />} />

        {/* Redirects a successful login or a authenticated users attempt to reach the login page back to home page. */}
        <Route path="/login" element={<Navigate to="/" replace />} />
        {/* Redirects an authorised user navigating to a non-existent page to the error page. */}
        <Route path="/*" element={<Navigate to="/error" replace />} />
      </Route>

      <Route path="/error" element={<ErrorPage />}></Route>
    </Routes>
  );
}

/**
 * Used to route unauthenticated users to the
 * login page and authenticated users to the homepage.
 *
 * @param { IRouterAppProps } props
 * @return { JSX.Element }
 */
class AppRouter extends React.Component<IRouterAppProps, IRouterAppState> {
  /**
   * The state object for the router.
   *
   * @memberof AppRouter
   */
  public readonly state = {
    hasRouterLoaded: false
  }

  /**
   * Creates an instance of AppRouter.
   *
   * @param { IRouterAppProps } props
   * @memberof AppRouter
   */
  public constructor(props: IRouterAppProps) {
    super(props);
  }

  /**
   * Checks if Cognito has a current valid session, if it does the state is populated.
   *
   * @return { Promise<void> }
   * @memberof AppRouter
   */
  public async componentDidMount(): Promise<void> {
    try {
      const cognito = await CognitoService.currentSession();

      if (cognito) {
        await AuthService.me();
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}

    this.setState({ hasRouterLoaded: true })
  }

  /**
   * Reacts render method.
   *
   * Renders the element this component represents.
   *
   * @return { JSX.Element }
   * @memberof AppRouter
   */
  public render(): JSX.Element {
    if (!this.state.hasRouterLoaded || this.props.isAppLoading) {
      return <Loading />;
    }

    return (
      <BrowserRouter>
        {this.props.isAuthenticated ? (
          <AuthenticatedRouter />
        ) : (
          <Routes>
            <Route path="/login" element={<LoginPage />}></Route>
            <Route path="/error" element={<ErrorPage />}></Route>
            <Route path="" element={<Navigate to="/login" replace />}></Route>
            <Route path="/" element={<Navigate to="/login" replace />}></Route>
            <Route path="/documentation" element={<Navigate to="/login" replace />}></Route>
            <Route path="/guide" element={<Navigate to="/login" replace />}></Route>
            <Route path="/settings" element={<Navigate to="/login" replace />}></Route>
            <Route path="/*" element={<Navigate to="/error" replace />}></Route>
          </Routes>
        )}
      </BrowserRouter>
    );
  }
}

/**
 * Maps the stores state to a components props.
 *
 * @param { IRootAppState } { cognito }
 * @return { IRouterAppProps }
 */
const mapStateToProps = ({
  cognito,
}: IRootAppState): IRouterAppProps => {
  return {
    isAuthenticated: cognito.isAuthenticated,
    isAppLoading: cognito.loading,
  };
};

export default connect(mapStateToProps)(AppRouter);